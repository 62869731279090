import React from "react"

import SEO from "src/components/seo"
import "./404.scss";

const NotFoundPage = () => (
  <div className="container container-sm not-found">
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <a href="/">Go Home</a>
  </div>
)

export default NotFoundPage
